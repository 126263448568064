import axios from '@/axios/globalaxios'

export const listCourseOpenFundInApplication = data =>{
    return axios({
        url: "/tradelab/openFund/listCourseOpenFundInApplication",
        method: 'post',
        data:data
    })
}

export const openFundApproved = data =>{
    return axios({
        url: "/tradelab/openFund/openFundApproved",
        method: 'post',
        data:data
    })
}

export const openFundInfo = data =>{
    return axios({
        url: "/tradelab/openFund/openFundInfo",
        method: 'post',
        data:data
    })
}


